import {alpha, IconButton, Toolbar, Tooltip, Typography} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface EnhancedTableHeadComponentProps{
    numSelected: number,
    value: string,
}

export default function EnhancedTableToolbarComponent(props: EnhancedTableHeadComponentProps){

    const { numSelected, value } = props;

    return(
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%', fontSize:{xs:'medium',sm:'large',md:'x-large',lg:'xx-large'} }}
                    id="tableTitle"
                    component="div"
                >
                    Comparator Tool {value==='' ? null :  ': '+value}
                </Typography>
            )}
            {
             numSelected > 0 ?
                 <Tooltip title="Actions">
                     <IconButton>
                         <MoreVertIcon />
                     </IconButton>
                 </Tooltip>
                 :
                 null
            }
        </Toolbar>
    );
}