import {
    User,
    UserManager,
    UserManagerSettings,
    WebStorageStateStore
} from "oidc-client-ts";
import EnvConfig from "../EnvConfig/EnvConfig";

class Authentication {

    private userManager: UserManager;
    public isLoggedIn = false;

    private constructor() {
        const settings: UserManagerSettings = {
            userStore: new WebStorageStateStore({store: window.localStorage}),
            stateStore: new WebStorageStateStore({store: window.localStorage}),
            authority: EnvConfig.REACT_APP_AUTH_AUTHORITY,
            client_id: EnvConfig.REACT_APP_AUTH_CLIENT_ID,
            redirect_uri: EnvConfig.REACT_APP_AUTH_REDIRECT_URI,
            scope: 'openid profile prd:sty email',
            response_type: "code",
            loadUserInfo: true,
            response_mode: 'fragment',
            metadata: {
                issuer: EnvConfig.REACT_APP_AUTH_AUTHORITY,
                authorization_endpoint: EnvConfig.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT,
                userinfo_endpoint: EnvConfig.REACT_APP_AUTH_USER_ENDPOINT,
                end_session_endpoint: EnvConfig.REACT_APP_AUTH_END_SESSION_ENDPOINT,
                token_endpoint: EnvConfig.REACT_APP_AUTH_TOKEN_ENDPOINT,
                revocation_endpoint: EnvConfig.REACT_APP_AUTH_REVOCATION_ENDPOINT,
            }
        }
        this.userManager = new UserManager(settings);
    }

    public static INSTANCE = new Authentication();

    public async ensureLog(): Promise<User | void> {
        if (!this.isLoggedIn) {
            this.isLoggedIn = true;
            return await this.userManager.signinRedirect();
        }
        return;
    }

    public async Callback(): Promise<User | void> {
        return await this.userManager.signinCallback();
    }

    public async Logout(): Promise<void> {
        this.isLoggedIn = false;
        return window.location.replace(EnvConfig.REACT_APP_AUTH_END_SESSION_ENDPOINT);
    }

}

export default Authentication;
