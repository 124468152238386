import {
    Box, Button,
    ButtonGroup,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    SvgIcon,
    Tooltip,
    Typography
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import IconButton from "@mui/material/IconButton";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar, VariantType} from 'notistack';
import { ReactComponent as WindowsLogo } from '../../Assets/windows.svg';
import { ReactComponent as LinuxLogo } from "../../Assets/linux.svg";

interface State{
    OS: string,
    password: string,
    showPassword: boolean,
    stellantisID: string,
    server: string,
    servers: string[],
    serversOS: string[],
}

export default function TEICHComponent(){

    const [valuesTEICH, setValuesTEICH] = React.useState<State>({
        OS: '',
        password: '',
        showPassword: false,
        stellantisID: '',
        server: '',
        servers: [],
        serversOS: [],
    })

    const { enqueueSnackbar } = useSnackbar();

    const handleChangeStellantisId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesTEICH({
            ...valuesTEICH,
            stellantisID: event.target.value,
        })
    }

    const handleClickDeleteServer = (serverToRemove:string) => {
        let index = valuesTEICH.servers.findIndex(elementToFind => elementToFind === serverToRemove);
        valuesTEICH.serversOS.splice(index,1);
        setValuesTEICH({
            ...valuesTEICH,
            serversOS: [...valuesTEICH.serversOS],
            servers: [...valuesTEICH.servers.filter(element => element !== serverToRemove)],
        })
    }

    const handleChangeServer = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesTEICH({
            ...valuesTEICH,
            server: event.target.value,
        })
    }

    const handleCreateSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(message,{ variant })
    }

    const handleClickServers = () => {
        if(valuesTEICH.servers.find(element => element === valuesTEICH.server) === undefined && valuesTEICH.OS !== '' && valuesTEICH.server !== ''){
            handleCreateSnackBar("Server "+'"'+valuesTEICH.server+'"'+" was successfully added to the list","success");
            setValuesTEICH({
                ...valuesTEICH,
                servers: [...valuesTEICH.servers, valuesTEICH.server],
                serversOS: [...valuesTEICH.serversOS, valuesTEICH.OS],
            })
        }
        else if (valuesTEICH.servers.find(element => element === valuesTEICH.server) === undefined && valuesTEICH.OS === '' && valuesTEICH.server !== ''){
            handleCreateSnackBar("OS isn't defined, please select one OS", "error");
        }
        else if (valuesTEICH.servers.find(element => element === valuesTEICH.server) !== undefined){
            handleCreateSnackBar("Server "+'"'+valuesTEICH.server+'"'+" already exist in the list", "error");
        }
        else if(valuesTEICH.server === ''){
            handleCreateSnackBar("Please enter a server name","error");
        }
        return;
    }

    const handleClickShowPassword = () => {
        setValuesTEICH({
            ...valuesTEICH,
            showPassword: !valuesTEICH.showPassword,
        })
    }

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesTEICH({
            ...valuesTEICH,
            password: event.target.value
        })
    }

    const handleChange = (event: SelectChangeEvent) => {
        setValuesTEICH({
            ...valuesTEICH,
            OS: event.target.value as string
        })
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }

    return(
        <Paper
            sx={{
                minHeight: {xs:'calc(100vh - 58px)', md:"auto"},
                minWidth: {xs:'100%', md:"80vw"},
                maxWidth: {xs:'100%', md:'80vw'},
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: {xs:'58px', md:'calc(2% + 70px)'},
                padding: '30px',
                borderRadius: {xs:'0', md:'10px'},
                display:'flex',
                alignItems:'center',
                flexDirection:'column',
            }}
            elevation={3}
        >
            <Typography
                sx={{fontSize:'xx-large', marginBottom:'20px'}}
            >
                Legacy Discovery
            </Typography>
            <FormControl sx={{width:{xs:'100%',md:'50%'}}}>
                <InputLabel id="set-OS">Operating System</InputLabel>
                <Select
                    labelId="set-OS"
                    id="set-OS"
                    value={valuesTEICH.OS}
                    label="Operating System"
                    onChange={handleChange}
                >
                    <MenuItem value={'Windows'}>Windows</MenuItem>
                    <MenuItem value={'Linux'}>Linux</MenuItem>
                </Select>
            </FormControl>
            <Box
                sx={
                    {
                        width: {xs:'100%',md:'50%'},
                        display: 'flex',
                        flexDirection: {xs:'column', md:'row'},
                        marginTop: '20px',
                        justifyContent: {md:'space-between'},
                        alignItems:{xs:'center'}
                    }
                }
            >
                <FormControl
                    variant="outlined"
                    sx={{width: {xs:'100%', md:'49%'}, marginBottom: {xs:'20px', md: '0'}}}
                >
                    <InputLabel
                        htmlFor="outlined-adornment-StellantisID"
                    >
                        Stellantis ID
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-StellantisID"
                        type='text'
                        value={valuesTEICH.stellantisID}
                        onChange={handleChangeStellantisId}
                        endAdornment={
                            <InputAdornment position='end'>
                                <AccountBoxIcon/>
                            </InputAdornment>
                        }
                        label="Stellantis ID"
                    />
                </FormControl>
                <FormControl
                    variant='outlined'
                    sx={{width: {xs:'100%', md:'49%'}}}
                >
                    <InputLabel
                        htmlFor='outlined-adornment-password'
                    >
                        Password
                    </InputLabel>
                    <OutlinedInput
                        id='outlined-adornment-password'
                        type={valuesTEICH.showPassword ? 'text' : 'password'}
                        value={valuesTEICH.password}
                        onChange={handleChangePassword}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {valuesTEICH.showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                        label='password'
                    />
                </FormControl>
            </Box>
            <FormControl
                variant="outlined"
                sx={{width:{xs:'100%',md:'50%', marginTop:'20px'}}}
            >
                <InputLabel
                    htmlFor="outlined-adornment-server"
                >
                    Server
                </InputLabel>
                <OutlinedInput
                    id='outlined-adornment-server'
                    type='text'
                    value={valuesTEICH.server}
                    onChange={handleChangeServer}
                    endAdornment={
                        <InputAdornment
                            position="end"
                        >
                            <IconButton
                                onClick={handleClickServers}
                                edge="end"
                            >
                                <AddIcon/>
                            </IconButton>
                        </InputAdornment>
                    }
                    label='Server'
                />
            </FormControl>
            <Box sx={{width:{xs:'100%',md:'50%'}, textAlign:'center'}}>
                {
                    valuesTEICH.servers.map((element:string,index:number) =>{
                        return(
                            <ButtonGroup sx={{margin:'5px'}} key={element}>
                                <Tooltip title={valuesTEICH.serversOS[index]+' server'}>
                                    <Button>
                                        {valuesTEICH.serversOS[index] === 'Windows' ? <SvgIcon><WindowsLogo/></SvgIcon> : <SvgIcon><LinuxLogo /></SvgIcon>}
                                    </Button>
                                </Tooltip>
                                <Tooltip title='Server name'>
                                    <Button>
                                        {element}
                                    </Button>
                                </Tooltip>
                                <Tooltip title={'Delete '+element+' server'}>
                                    <Button sx={{'&:hover':{color:'#f44336', borderColor:'#f44336'}}} onClick={()=>handleClickDeleteServer(element)}>
                                        <DeleteIcon/>
                                    </Button>
                                </Tooltip>
                            </ButtonGroup>
                        )
                    })
                }
            </Box>
        </Paper>
    )
}