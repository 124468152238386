import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import React from "react";
import {Divider, Drawer, ListItemIcon, MenuItem, Typography} from "@mui/material";
import Authentication from "../../../Authentication/Authentication";
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import EnvConfig from "../../../EnvConfig/EnvConfig";

export default function ProfileComponent(props:any){

    const [profileDrawer, setProfileDrawer] = React.useState(false);

    const DrawerOpener = () => {
        return setProfileDrawer(!profileDrawer);
    }

    const LogOut = () => {
        return Authentication.INSTANCE.Logout().then(()=>window.location.replace(EnvConfig.REACT_APP_AUTH_URL));
    }

    const getUsername = () => {
        let x = window.localStorage.getItem("oidc.user:".concat(EnvConfig.REACT_APP_AUTH_AUTHORITY).concat(":").concat(EnvConfig.REACT_APP_AUTH_CLIENT_ID));
        if(window.location.href.indexOf('ACCESS_DENIED') !== -1){
            window.location.href='/error';
        }
        else{
            try{
                return JSON.parse(x === null ? 'failed' : x);
            }
            catch{
                Authentication.INSTANCE.isLoggedIn = false
                return Authentication.INSTANCE.ensureLog()
            }
        }
    }

    return(
        <Box>
            <IconButton onClick={DrawerOpener} sx={{ p: 0 }}>
                <AccountCircleIcon sx={{height:"35px",width:"35px", color:"white"}}/>
            </IconButton>
            <Drawer
                anchor="right"
                open={profileDrawer}
                onClose={DrawerOpener}
            >
                <Typography textAlign="center">
                    {window.location.pathname === '/error' ? null : getUsername().profile.sub}
                </Typography>
                <Typography textAlign="center">
                    {window.location.pathname === '/error' ? null : getUsername().profile.firstname+' '+getUsername().profile.lastname}
                </Typography>
                <Divider/>
                <MenuItem onClick={LogOut}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
                <MenuItem onClick={props.setMode}>
                    <ListItemIcon>
                        {props.mode ? <Brightness4Icon/> : <Brightness7Icon/>}
                    </ListItemIcon>
                    Switch mode
                </MenuItem>
            </Drawer>
        </Box>
    )
}
