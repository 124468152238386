import {
    Alert,
    AlertTitle,
    Box, Button,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel, MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent, Step, StepIconProps, StepLabel, Stepper,
    TextField,
    Typography
} from "@mui/material";
import React from 'react';
import dayjs, {Dayjs} from 'dayjs';
import Stack from '@mui/material/Stack';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import InfoIcon from '@mui/icons-material/Info';
import {styled} from '@mui/material/styles';
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useSnackbar, VariantType} from "notistack";
import Tooltip from '@mui/material/Tooltip';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import SummaryTableComponent from "./SummaryTableComponent/SummaryTableComponent";
import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

function ColorlibStepIcon(props: StepIconProps) {
    const {active, completed, className} = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <TextFieldsIcon/>,
        2: <DescriptionIcon/>,
        3: <CheckCircleIcon/>,
    };

    return (
        <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({theme, ownerState}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

interface State {
    BIS: string,
    ProjectIDInCMDB: string,
    TypeAndCMDB: string,
    ApplicationOwner: string,
    ITEResponsible: string,
    VersionDate: Dayjs | null,
    DocumentVersion: number,
    LastUpdate: Dayjs | null,
    ApplicationNameId: string,
    ApplicationNameLabel: string,
    ITDomain: string,
    GeographyCoverage: string,
    FunctionalComplexity: string,
    SolutionType: string,
    PackageName: string,
    DegreeOfCustomization: string,
    MainAppProgrammingLanguage: string,
    SecondAppProgrammingLanguage: string,
    NameOfDevelopmentFramework: string,
    NameOfTheMainDatabaseManagementSystem: string,
    ApplicationServerAndVersion: string,
    TechnicalObsolescence: string,
    SourceCodeAvailability: string,
    ApplicationAuthentication: string,
    JavaUsed: string,
    JDKVersion: string,
    DevDedicated: string,
    PreprodDedicated: string,
    PreprodAtImageOfTheProd: string,
    TechnicalDependency: string,
    Backup: string,
    ToolboxVersionsUsed: string,
    SpecificProductsEmbedded: string,
    IndusGen: string,
    LicenseForSomeProducts: string,
    CIQIntegration: string,
    StopAndStartAutomatedProcess: string,
    CMDBUpToDate: string,
    TimeToMarketRequiredByBusiness: string,
    NumberOfActiveEndUsers: string,
    AreThereRegulatoryRequirements: string,
    TypeOfRegulatoryRequirement: string,
    FunctionalDependencyOfTheApplication: string,
    InterdependenceWithOtherApplications: string,
    NumberOfMajorReleasesDeliveredOverTheLast12Months: number,
    NextMajorTechnicalChange: string,
    CriticalityScoreGivenByOwnAssessment: string,
    ServiceLevelSLA: number,
    CurrentResponseTime: string,
    DisasterRecoveryPlan: string,
    MainInfrastructureSupplierName: string,
    SharedInfrastructure: string,
    Monitoring: string,
    SchedulingSolution: string,
    FileTransferSolution: string,
    TotalServers: string,
    ProductionServers: string,
    PreProductionServers: string,
    DevServersNumber: string,
    MainOS: string,
    NFSMQKafka: string,
    HowIsTheApplicationAccessedUsed: string,
    IfWebApplication: string,
    ExternalConnectivityWithPartners: string,
    IntegrationProtocols: string,
    LatencySensitivity: string,
    SensitivityToIPChange: string,
    HostedOnaVirtualMachine: string,
    ExternalSoftwareDependency: string,
    AnySpecificInformationOrConstraintWeShouldKnow: string,
    SupportHoursToEndUsers: string,
    CurrentApplicationAvailability: string,
    PreferredCutOverWindow: string,
    NumberOfTransactions: number,
    ResponseTimeFor: string,
    BatchNumber: string,
    BatchWindow: string,
    BatchDuration: string,
    TestProcedureAndFunctionalTestSet: string,
    ExistenceOfSLAContract: string,
}

export default function M2CFormComponent() {

    const steps = ['Application Name', 'M2C Form', 'Download, save or share'];

    const [step, setStep] = React.useState(0);

    const handleClickNextStep = () => {
        if (step === 0 && valuesM2C.ApplicationNameId !== '') {
            setStep(prevState => prevState + 1);
        } else if (step !== 0) {
            setStep(prevState => prevState + 1);
        } else {
            handleCreateSnackBar('Please enter a valid application name', "error");
        }
    }

    const handleClickPreviousStep = () => {
        setStep(prevState => prevState - 1);
    }

    const {enqueueSnackbar} = useSnackbar();

    const handleCreateSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(message, {variant})
    }

    const currentDate = () => dayjs(new Date().toJSON());

    const [valuesM2C, setValuesM2C] = React.useState<State>({
        BIS: '',
        ProjectIDInCMDB: '',
        TypeAndCMDB: '',
        ApplicationOwner: '',
        ITEResponsible: '',
        VersionDate: currentDate(),
        DocumentVersion: 0,
        LastUpdate: currentDate(),
        ApplicationNameId: '',
        ApplicationNameLabel: '',
        ITDomain: '',
        GeographyCoverage: '',
        FunctionalComplexity: '',
        SolutionType: '',
        PackageName: '',
        DegreeOfCustomization: '',
        MainAppProgrammingLanguage: '',
        SecondAppProgrammingLanguage: '',
        NameOfDevelopmentFramework: '',
        NameOfTheMainDatabaseManagementSystem: '',
        ApplicationServerAndVersion: '',
        TechnicalObsolescence: '',
        SourceCodeAvailability: '',
        ApplicationAuthentication: '',
        JavaUsed: '',
        JDKVersion: '',
        DevDedicated: '',
        PreprodDedicated: '',
        PreprodAtImageOfTheProd: '',
        TechnicalDependency: '',
        Backup: '',
        ToolboxVersionsUsed: '',
        SpecificProductsEmbedded: '',
        IndusGen: '',
        LicenseForSomeProducts: '',
        CIQIntegration: '',
        StopAndStartAutomatedProcess: '',
        CMDBUpToDate: '',
        TimeToMarketRequiredByBusiness: '',
        NumberOfActiveEndUsers: '',
        AreThereRegulatoryRequirements: '',
        TypeOfRegulatoryRequirement: '',
        FunctionalDependencyOfTheApplication: '',
        InterdependenceWithOtherApplications: '',
        NumberOfMajorReleasesDeliveredOverTheLast12Months: 0,
        NextMajorTechnicalChange: '',
        CriticalityScoreGivenByOwnAssessment: '',
        ServiceLevelSLA: 0,
        CurrentResponseTime: '',
        DisasterRecoveryPlan: '',
        MainInfrastructureSupplierName: '',
        SharedInfrastructure: '',
        Monitoring: '',
        SchedulingSolution: '',
        FileTransferSolution: '',
        TotalServers: '',
        ProductionServers: '',
        PreProductionServers: '',
        DevServersNumber: '',
        MainOS: '',
        NFSMQKafka: '',
        HowIsTheApplicationAccessedUsed: '',
        IfWebApplication: '',
        ExternalConnectivityWithPartners: '',
        IntegrationProtocols: '',
        LatencySensitivity: '',
        SensitivityToIPChange: '',
        HostedOnaVirtualMachine: '',
        ExternalSoftwareDependency: '',
        AnySpecificInformationOrConstraintWeShouldKnow: '',
        SupportHoursToEndUsers: '',
        CurrentApplicationAvailability: '',
        PreferredCutOverWindow: '',
        NumberOfTransactions: 0,
        ResponseTimeFor: '',
        BatchNumber: '',
        BatchWindow: '',
        BatchDuration: '',
        TestProcedureAndFunctionalTestSet: '',
        ExistenceOfSLAContract: '',
    });

    const handleChangeBIS = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            BIS: event.target.value,
        })
    }

    const handleChangeProjectIdInCMDB = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ProjectIDInCMDB: event.target.value,
        })
    }

    const handleChangeApplicationOwner = (event: React.ChangeEvent<HTMLInputElement>) => ({
        ...valuesM2C,
        ApplicationOwner: event.target.value,
    })

    const handleChangeTypeAndCMDB = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            TypeAndCMDB: event.target.value as string,
        });
    };

    const handleChangeITEResponsible = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ITEResponsible: event.target.value,
        })
    }

    const handleChangeVersionDate = (newDate: Dayjs | null) => {
        setValuesM2C({
            ...valuesM2C,
            VersionDate: newDate,
        })
    };

    const handleChangeLastUpdate = (newDate: Dayjs | null) => {
        setValuesM2C({
            ...valuesM2C,
            LastUpdate: newDate,
        })
    }

    const handleChangeApplicationId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ApplicationNameId: event.target.value,
        })
    }

    const handleChangeApplicationLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ApplicationNameLabel: event.target.value,
        })
    }

    const handleChangeITDomain = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ITDomain: event.target.value,
        })
    }

    const handleChangeGeographyCoverage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            GeographyCoverage: event.target.value,
        })
    }

    const handleChangeFunctionalComplexity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            FunctionalComplexity: event.target.value,
        })
    }

    const handleChangeSolutionType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            SolutionType: event.target.value,
        })
    }

    const handleChangePackageName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            PackageName: event.target.value,
        })
    }

    const handleChangeDegreeOfCustomization = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            DegreeOfCustomization: event.target.value,
        })
    }

    const handleChangeMainAppProgrammingLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            MainAppProgrammingLanguage: event.target.value,
        })
    }

    const handleChangeSecondAppProgrammingLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            SecondAppProgrammingLanguage: event.target.value,
        })
    }

    const handleChangeNameOfDevelopmentFramework = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            NameOfDevelopmentFramework: event.target.value,
        })
    }

    const handleChangeNameOfTheMainDatabaseManagementSystem = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            NameOfTheMainDatabaseManagementSystem: event.target.value,
        })
    }

    const handleChangeApplicationServerAndVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ApplicationServerAndVersion: event.target.value,
        })
    }

    const handleChangeTechnicalObsolescence = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            TechnicalObsolescence: event.target.value,
        })
    }

    const handleChangeSourceCodeAvailability = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            SourceCodeAvailability: event.target.value,
        })
    }

    const handleChangeApplicationAuthentication = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ApplicationAuthentication: event.target.value,
        })
    }

    const handleChangeJavaUsed = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            JavaUsed: event.target.value as string,
        })
    }

    const handleChangeJDKVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            JDKVersion: event.target.value,
        })
    }

    const handleChangeDevDedicated = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            DevDedicated: event.target.value as string,
        })
    }

    const handleChangePreprodDedicated = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            PreprodDedicated: event.target.value as string,
        })
    }

    const handleChangePreprodAtImageOfTheProd = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            PreprodAtImageOfTheProd: event.target.value as string,
        })
    }

    const handleChangeTechnicalDependency = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            TechnicalDependency: event.target.value,
        })
    }

    const handleChangeBackup = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            Backup: event.target.value,
        })
    }

    const handleChangeToolboxVersionsUsed = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ToolboxVersionsUsed: event.target.value,
        })
    }

    const handleChangeSpecificProductsEmbedded = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            SpecificProductsEmbedded: event.target.value,
        })
    }

    const handleChangeIndusGen = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            IndusGen: event.target.value as string,
        })
    }

    const handleChangeLicenseForSomeProducts = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            LicenseForSomeProducts: event.target.value,
        })
    }

    const handleChangeCIQIntegration = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            CIQIntegration: event.target.value,
        })
    }

    const handleChangeStopAndStartAutomatedProcess = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            StopAndStartAutomatedProcess: event.target.value,
        })
    }

    const handleChangeCMDBUpToDate = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            CMDBUpToDate: event.target.value as string,
        })
    }

    const handleChangeTimeToMarketRequiredByBusiness = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            TimeToMarketRequiredByBusiness: event.target.value,
        })
    }

    const handleChangeNumberOfActiveEndUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            NumberOfActiveEndUsers: event.target.value,
        })
    }

    const handleChangeAreThereRegulatoryRequirements = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            AreThereRegulatoryRequirements: event.target.value as string,
        })
    }

    const handleChangeTypeOfRegulatoryRequirement = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            TypeOfRegulatoryRequirement: event.target.value,
        })
    }

    const handleChangeFunctionalDependencyOfTheApplication = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            FunctionalDependencyOfTheApplication: event.target.value,
        })
    }

    const handleChangeInterdependenceWithOtherApplications = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            InterdependenceWithOtherApplications: event.target.value,
        })
    }

    const handleChangeNumberOfMajorReleasesDeliveredOverTheLast12Months = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            NumberOfMajorReleasesDeliveredOverTheLast12Months: parseInt(event.target.value, 10),
        })
    }

    const handleChangeNextMajorTechnicalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            NextMajorTechnicalChange: event.target.value,
        })
    }

    const handleChangeCriticalityScoreGivenByOwnAssessment = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            CriticalityScoreGivenByOwnAssessment: event.target.value,
        })
    }

    const handleChangeCurrentResponseTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            CurrentResponseTime: event.target.value,
        })
    }

    const handleChangeDisasterRecoveryPlan = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            DisasterRecoveryPlan: event.target.value as string,
        })
    }

    const handleChangeMainInfrastructureSupplierName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            MainInfrastructureSupplierName: event.target.value,
        })
    }

    const handleChangeSharedInfrastructure = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            SharedInfrastructure: event.target.value,
        })
    }

    const handleChangeMonitoring = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            Monitoring: event.target.value,
        })
    }

    const handleChangeSchedulingSolution = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            SchedulingSolution: event.target.value,
        })
    }

    const handleChangeFileTransferSolution = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            FileTransferSolution: event.target.value,
        })
    }

    const handleChangeTotalServers = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            TotalServers: event.target.value,
        })
    }

    const handleChangeProductionServers = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ProductionServers: event.target.value,
        })
    }

    const handleChangePreProductionServers = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            PreProductionServers: event.target.value,
        })
    }

    const handleChangeDevServersNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            DevServersNumber: event.target.value,
        })
    }

    const handleChangeMainOS = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            MainOS: event.target.value,
        })
    }

    const handleChangeNFSMQKafka = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            NFSMQKafka: event.target.value,
        })
    }

    const handleChangeHowIsTheApplicationAccessedUsed = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            HowIsTheApplicationAccessedUsed: event.target.value as string,
        })
    }

    const handleChangeIfWebApplication = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            IfWebApplication: event.target.value as string,
        })
    }

    const handleChangeExternalConnectivityWithPartners = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            ExternalConnectivityWithPartners: event.target.value as string,
        })
    }

    const handleChangeIntegrationProtocols = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            IntegrationProtocols: event.target.value as string,
        })
    }

    const handleChangeLatencySensitivity = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            LatencySensitivity: event.target.value as string,
        })
    }

    const handleChangeSensitivityToIPChange = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            SensitivityToIPChange: event.target.value as string,
        })
    }

    const handleChangeHostedOnaVirtualMachine = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            HostedOnaVirtualMachine: event.target.value as string,
        })
    }

    const handleChangeExternalSoftwareDependency = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ExternalSoftwareDependency: event.target.value,
        })
    }

    const handleChangeAnySpecificInformationOrConstraintWeShouldKnow = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            AnySpecificInformationOrConstraintWeShouldKnow: event.target.value,
        })
    }

    const handleChangeSupportHoursToEndUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            SupportHoursToEndUsers: event.target.value,
        })
    }

    const handleChangeCurrentApplicationAvailability = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            CurrentApplicationAvailability: event.target.value,
        })
    }

    const handleChangePreferredCutOverWindow = (event: SelectChangeEvent) => {
        setValuesM2C({
            ...valuesM2C,
            PreferredCutOverWindow: event.target.value as string,
        })
    }

    const handleChangeNumberOfTransactions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            NumberOfTransactions: parseInt(event.target.value, 10),
        })
    }

    const handleChangeResponseTimeFor = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ResponseTimeFor: event.target.value,
        })
    }

    const handleChangeBatchNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            BatchNumber: event.target.value,
        })
    }

    const handleChangeBatchWindow = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            BatchWindow: event.target.value,
        })
    }

    const handleChangeBatchDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            BatchDuration: event.target.value,
        })
    }

    const handleChangeTestProcedureAndFunctionalTestSet = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            TestProcedureAndFunctionalTestSet: event.target.value,
        })
    }

    const handlechangeExistenceOfSLAContract = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesM2C({
            ...valuesM2C,
            ExistenceOfSLAContract: event.target.value,
        })
    }

    const handleCreatePDF = () => {
        const doc = new jsPDF();
        autoTable(doc, { html: '#M2CSummaryTable' });
        doc.save('M2CForm_'+valuesM2C.ApplicationNameId+'.pdf');
    }

    return (
        <Paper
            sx={{
                minHeight: {xs: 'calc(100vh - 58px)', md: "auto"},
                minWidth: {xs: '100%', md: "80vw"},
                maxWidth: {xs: '100%', md: '80vw'},
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: {xs: '58px', md: 'calc(2% + 70px)'},
                marginBottom: '58px',
                padding: `30px 0 ${step === 0 ? 0 : '30px'} 0`,
                borderRadius: {xs: '0', md: '10px'},
            }}
            elevation={3}
        >
            <Stack
                sx={{width: '100%', display: 'flex', alignItems: 'center'}}
                spacing={3}
            >
                <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector/>}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Divider sx={{width: '100%'}}/>
                {
                    step === 0 ?
                        (
                            <React.Fragment>
                                <FormControl
                                    variant="outlined"
                                >
                                    <InputLabel
                                        htmlFor='ApplicationName'
                                    >
                                        Application Name
                                    </InputLabel>
                                    <OutlinedInput
                                        label="Application Name"
                                        id="ApplicationName"
                                        onChange={handleChangeApplicationId}
                                        value={valuesM2C.ApplicationNameId}
                                    />
                                </FormControl>
                                <Divider sx={{width: '100%'}}/>
                            </React.Fragment>
                        )
                        :
                        null
                }
                {
                    step === 1 ?
                        (
                            <React.Fragment>
                                <Typography sx={{fontSize: 'xx-large'}}>
                                    TEIC.H M2C
                                </Typography>
                                <Divider sx={{width: '100%'}}/>
                                <Typography sx={{fontSize: 'large'}}>
                                    identification
                                </Typography>
                                <Divider sx={{width: '70%'}}/>
                                <Box sx={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack spacing={2}>
                                            <FormControl
                                                variant="outlined"
                                            >
                                                <InputLabel
                                                    htmlFor='BIS'
                                                >
                                                    BIS
                                                </InputLabel>
                                                <OutlinedInput
                                                    label="BIS"
                                                    id="BIS"
                                                    onChange={handleChangeBIS}
                                                    value={valuesM2C.BIS}
                                                />
                                            </FormControl>
                                            <FormControl
                                                variant="outlined"
                                            >
                                                <InputLabel
                                                    htmlFor='ProjectIDInCMDB'
                                                >
                                                    Project ID in CMDB
                                                </InputLabel>
                                                <OutlinedInput
                                                    label="Project ID in CMDB"
                                                    id="ProjectIDInCMDB"
                                                    onChange={handleChangeProjectIdInCMDB}
                                                    value={valuesM2C.ProjectIDInCMDB}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <Tooltip title={
                                                                <Box>
                                                                    <Typography>xP : Reflex name</Typography>
                                                                    <Typography>xF : ID in CMDB</Typography>
                                                                </Box>
                                                            }
                                                            >
                                                                <IconButton>
                                                                    <InfoIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel id="TypeAndCMDB">Type and CMDB</InputLabel>
                                                <Select
                                                    labelId="TypeAndCMDB"
                                                    id="TypeAndCMDB"
                                                    value={valuesM2C.TypeAndCMDB}
                                                    label="TypeAndCMDB"
                                                    onChange={handleChangeTypeAndCMDB}
                                                >
                                                    <MenuItem value="xP">xP</MenuItem>
                                                    <MenuItem value="xF">xF</MenuItem>
                                                    <MenuItem value="Stellantis">Stellantis</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl
                                                variant="outlined"
                                            >
                                                <InputLabel
                                                    htmlFor='ApplicationOwner'
                                                >
                                                    Application Owner
                                                </InputLabel>
                                                <OutlinedInput
                                                    label="Application Owner"
                                                    id="ApplicationOwner"
                                                    onChange={handleChangeApplicationOwner}
                                                    value={valuesM2C.ApplicationOwner}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <Tooltip title={
                                                                <Box>
                                                                    <Typography>People in charge of the application
                                                                        (PGA)</Typography>
                                                                    <Typography>Format : PGA : ID</Typography>
                                                                </Box>
                                                            }
                                                            >
                                                                <IconButton>
                                                                    <InfoIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                            <FormControl
                                                variant="outlined"
                                            >
                                                <InputLabel
                                                    htmlFor='ITEResponsible'
                                                >
                                                    ITE Responsible
                                                </InputLabel>
                                                <OutlinedInput
                                                    label="ITE Responsible"
                                                    id="ITEResponsible"
                                                    onChange={handleChangeITEResponsible}
                                                    value={valuesM2C.ITEResponsible}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <Tooltip title={
                                                                <Box>
                                                                    <Typography>xP only</Typography>
                                                                    <Typography>Format :</Typography>
                                                                    <Typography>ITE : ID (PSASM RUN CELL)</Typography>
                                                                </Box>
                                                            }
                                                            >
                                                                <IconButton>
                                                                    <InfoIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                            <MobileDatePicker
                                                label="Version Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={valuesM2C.VersionDate}
                                                onChange={handleChangeVersionDate}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            <FormControl
                                                variant="outlined"
                                            >
                                                <InputLabel
                                                    htmlFor='outlined-start-adornment-Document-version'
                                                >
                                                    Document version
                                                </InputLabel>
                                                <OutlinedInput
                                                    label="Document version"
                                                    id="outlined-start-adornment-Document-version"
                                                />
                                            </FormControl>
                                            <MobileDatePicker
                                                label="Last Update"
                                                inputFormat="DD/MM/YYYY"
                                                value={valuesM2C.LastUpdate}
                                                onChange={handleChangeLastUpdate}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </Box>
                                <Typography sx={{fontSize: 'large'}}>
                                    Form
                                </Typography>
                                <Divider sx={{width: '70%'}}/>
                                <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', textAlign: 'center'}}>
                                    <Stack spacing={2}>
                                        <Typography sx={{fontSize: 'large'}}>
                                            General
                                        </Typography>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='ApplicationNameID'
                                            >
                                                Application Name (id)
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Application Name (id)"
                                                id="ApplicationNameID"
                                                onChange={handleChangeApplicationId}
                                                value={valuesM2C.ApplicationNameId}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='ApplicationNameLabel'
                                            >
                                                Application Name (label)
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Application Name (label)"
                                                id="ApplicationNameLabel"
                                                onChange={handleChangeApplicationLabel}
                                                value={valuesM2C.ApplicationNameLabel}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='ITDomain'
                                            >
                                                IT Domain
                                            </InputLabel>
                                            <OutlinedInput
                                                label="IT Domain"
                                                id="ITDomain"
                                                onChange={handleChangeITDomain}
                                                value={valuesM2C.ITDomain}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='GeographyCoverage'
                                            >
                                                Geography Coverage
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Geography Coverage"
                                                id="GeographyCoverage"
                                                onChange={handleChangeGeographyCoverage}
                                                value={valuesM2C.GeographyCoverage}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='FunctionalComplexity'
                                            >
                                                Functional Complexity
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Functional Complexity"
                                                id="FunctionalComplexity"
                                                onChange={handleChangeFunctionalComplexity}
                                                value={valuesM2C.FunctionalComplexity}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <Typography sx={{fontSize: 'large'}}>
                                            Technical
                                        </Typography>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='SolutionType'
                                            >
                                                Solution Type
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Solution Type"
                                                id="SolutionType"
                                                onChange={handleChangeSolutionType}
                                                value={valuesM2C.SolutionType}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='PackageName'
                                            >
                                                Package Name
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Package Name"
                                                id="PackageName"
                                                onChange={handleChangePackageName}
                                                value={valuesM2C.PackageName}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='DegreeOfCustomization'
                                            >
                                                Degree of Customization
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Degree of Customization"
                                                id="DegreeOfCustomization"
                                                onChange={handleChangeDegreeOfCustomization}
                                                value={valuesM2C.DegreeOfCustomization}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='MainAppProgrammingLanguage'
                                            >
                                                Main app programming language
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Main App Programming Language"
                                                id="MainAppProgrammingLanguage"
                                                onChange={handleChangeMainAppProgrammingLanguage}
                                                value={valuesM2C.MainAppProgrammingLanguage}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='SecondAppProgrammingLanguage'
                                            >
                                                Second app programming language
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Name of Development Framework"
                                                id="NameOfDevelopmentFramework"
                                                onChange={handleChangeSecondAppProgrammingLanguage}
                                                value={valuesM2C.SecondAppProgrammingLanguage}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='NameOfDevelopmentFramework'
                                            >
                                                Name of development framework
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Name of Development Framework"
                                                id="NameOfDevelopmentFramework"
                                                onChange={handleChangeNameOfDevelopmentFramework}
                                                value={valuesM2C.NameOfDevelopmentFramework}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='NameOfTheMainDatabaseManagementSystem'
                                            >
                                                Name of the main database management system
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Name of the main database management system"
                                                id="NameOfTheMainDatabaseManagementSystem"
                                                onChange={handleChangeNameOfTheMainDatabaseManagementSystem}
                                                value={valuesM2C.NameOfTheMainDatabaseManagementSystem}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='ApplicationServerAndVersion'
                                            >
                                                Application server and version
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Application server and version"
                                                id="ApplicationServerAndVersion"
                                                onChange={handleChangeApplicationServerAndVersion}
                                                value={valuesM2C.ApplicationServerAndVersion}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='TechnicalObsolescence'
                                            >
                                                Technical obsolescence
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Technical obsolescence"
                                                id="TechnicalObsolescence"
                                                onChange={handleChangeTechnicalObsolescence}
                                                value={valuesM2C.TechnicalObsolescence}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='SourceCodeAvailability'
                                            >
                                                Source code availability
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Source code availability"
                                                id="SourceCodeAvailability"
                                                onChange={handleChangeSourceCodeAvailability}
                                                value={valuesM2C.SourceCodeAvailability}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='ApplicationAuthentication'
                                            >
                                                Application authentication
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Application authentication"
                                                id="ApplicationAuthentication"
                                                onChange={handleChangeApplicationAuthentication}
                                                value={valuesM2C.ApplicationAuthentication}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <Stack direction="row" spacing={2}>
                                            <FormControl sx={{textAlign: 'left', width: '20%'}}>
                                                <InputLabel id="JavaUsed">Java is used ?</InputLabel>
                                                <Select
                                                    labelId="Java is used ?"
                                                    id="JavaUsed"
                                                    value={valuesM2C.JavaUsed}
                                                    label="Java is used ?"
                                                    onChange={handleChangeJavaUsed}
                                                >
                                                    <MenuItem value='No'>No</MenuItem>
                                                    <MenuItem value='Yes'>Yes</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl
                                                variant="outlined"
                                                sx={{width: '80%'}}
                                            >
                                                <InputLabel
                                                    htmlFor='JDKVersion'
                                                >
                                                    JDK version
                                                </InputLabel>
                                                <OutlinedInput
                                                    label="JDK Version"
                                                    id="JDKVersion"
                                                    onChange={handleChangeJDKVersion}
                                                    value={valuesM2C.JDKVersion}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <InfoIcon/>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Stack>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="DevDedicated">Dev dedicated ?</InputLabel>
                                            <Select
                                                labelId="Dev dedicated ?"
                                                id="DevDedicated"
                                                value={valuesM2C.DevDedicated}
                                                label="Dev dedicated ?"
                                                onChange={handleChangeDevDedicated}
                                            >
                                                <MenuItem value='No'>No</MenuItem>
                                                <MenuItem value='Yes'>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="PreprodDedicated">Preprod dedicated ?</InputLabel>
                                            <Select
                                                labelId="Preprod dedicated ?"
                                                id="PreprodDedicated"
                                                value={valuesM2C.PreprodDedicated}
                                                label="Preprod dedicated ?"
                                                onChange={handleChangePreprodDedicated}
                                            >
                                                <MenuItem value='No'>No</MenuItem>
                                                <MenuItem value='Yes'>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="PreprodAtImageOfTheProd">Preprod at image of the prod
                                                ?</InputLabel>
                                            <Select
                                                labelId="Preprod at image of the prod ?"
                                                id="PreprodAtImageOfTheProd"
                                                value={valuesM2C.PreprodAtImageOfTheProd}
                                                label="Preprod at image of the prod ?"
                                                onChange={handleChangePreprodAtImageOfTheProd}
                                            >
                                                <MenuItem value='No'>No</MenuItem>
                                                <MenuItem value='Yes'>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='TechnicalDependency'
                                            >
                                                Technical dependency
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Technical dependency"
                                                id="TechnicalDependency"
                                                onChange={handleChangeTechnicalDependency}
                                                value={valuesM2C.TechnicalDependency}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <Typography sx={{fontSize: 'large'}}>
                                            Indus/OPS
                                        </Typography>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='Backup'
                                            >
                                                Backup: NetBackup or other ?
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Backup: NetBackup or other ?"
                                                id="Backup"
                                                onChange={handleChangeBackup}
                                                value={valuesM2C.Backup}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='Toolbox'
                                            >
                                                Toolbox versions used
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Toolbox versions used"
                                                id="Toolbox"
                                                onChange={handleChangeToolboxVersionsUsed}
                                                value={valuesM2C.ToolboxVersionsUsed}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='SpecificProductsEmbedded'
                                            >
                                                Specific products embedded
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Specific products embedded"
                                                id="SpecificProductsEmbedded"
                                                onChange={handleChangeSpecificProductsEmbedded}
                                                value={valuesM2C.SpecificProductsEmbedded}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="IndusGen">IndusGen ?</InputLabel>
                                            <Select
                                                labelId="IndusGen ?"
                                                id="IndusGen"
                                                value={valuesM2C.IndusGen}
                                                label="IndusGen ?"
                                                onChange={handleChangeIndusGen}
                                            >
                                                <MenuItem value='No'>No</MenuItem>
                                                <MenuItem value='Yes'>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='LicenseForSomeProducts'
                                            >
                                                License for some products
                                            </InputLabel>
                                            <OutlinedInput
                                                label="License for some products"
                                                id="LicenseForSomeProducts"
                                                onChange={handleChangeLicenseForSomeProducts}
                                                value={valuesM2C.LicenseForSomeProducts}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='CIQIntegration'
                                            >
                                                CIQ Integration
                                            </InputLabel>
                                            <OutlinedInput
                                                label="CIQ Integration"
                                                id="CIQIntegration"
                                                onChange={handleChangeCIQIntegration}
                                                value={valuesM2C.CIQIntegration}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='StopAndStartAutomatedProcess'
                                            >
                                                Stop and start automated process
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Stop and start automated process"
                                                id="StopAndStartAutomatedProcess"
                                                onChange={handleChangeStopAndStartAutomatedProcess}
                                                value={valuesM2C.StopAndStartAutomatedProcess}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="CMDBUpToDate">CMDB up to date</InputLabel>
                                            <Select
                                                labelId="CMDB up to date"
                                                id="CMDBUpToDate"
                                                value={valuesM2C.CMDBUpToDate}
                                                label="CMDB up to date"
                                                onChange={handleChangeCMDBUpToDate}
                                            >
                                                <MenuItem value='No'>No</MenuItem>
                                                <MenuItem value='Yes'>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Typography sx={{fontSize: 'large'}}>
                                            Business
                                        </Typography>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='TimeToMarketRequiredByBusiness'
                                            >
                                                Time to market required by business
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Time to market required by business"
                                                id="TimeToMarketRequiredByBusiness"
                                                onChange={handleChangeTimeToMarketRequiredByBusiness}
                                                value={valuesM2C.TimeToMarketRequiredByBusiness}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='NumberOfActiveEndUsers'
                                            >
                                                Number of active end users
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Number of active end users"
                                                id="NumberOfActiveEndUsers"
                                                onChange={handleChangeNumberOfActiveEndUsers}
                                                value={valuesM2C.NumberOfActiveEndUsers}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="AreThereRegulatoryRequirements">Are there regulatory
                                                requirements</InputLabel>
                                            <Select
                                                labelId="Are there regulatory requirements"
                                                id="AreThereRegulatoryRequirements"
                                                value={valuesM2C.AreThereRegulatoryRequirements}
                                                label="Are there regulatory requirements"
                                                onChange={handleChangeAreThereRegulatoryRequirements}
                                            >
                                                <MenuItem value='No'>No</MenuItem>
                                                <MenuItem value='Yes'>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='TypeOfRegulatoryRequirement'
                                            >
                                                Type of regulatory requirement
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Type of regulatory requirement"
                                                id="TypeOfRegulatoryRequirement"
                                                onChange={handleChangeTypeOfRegulatoryRequirement}
                                                value={valuesM2C.TypeOfRegulatoryRequirement}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='FunctionalDependencyOfTheApplication'
                                            >
                                                Functional dependency of the application
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Functional dependency of the application"
                                                id="FunctionalDependencyOfTheApplication"
                                                onChange={handleChangeFunctionalDependencyOfTheApplication}
                                                value={valuesM2C.FunctionalDependencyOfTheApplication}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <Typography sx={{fontSize: 'large'}}>
                                            Lifecycle
                                        </Typography>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='InterdependenceWithOtherApplications'
                                            >
                                                Interdependence with other applications
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Interdependence with other applications"
                                                id="InterdependenceWithOtherApplications"
                                                onChange={handleChangeInterdependenceWithOtherApplications}
                                                value={valuesM2C.InterdependenceWithOtherApplications}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='NumberOfMajorReleasesDeliveredOverTheLast12Months'
                                            >
                                                Number of major releases delivered over the last 12 months
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Number of major releases delivered over the last 12 months"
                                                id="NumberOfMajorReleasesDeliveredOverTheLast12Months"
                                                onChange={handleChangeNumberOfMajorReleasesDeliveredOverTheLast12Months}
                                                type='number'
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='NextMajorTechnicalChange'
                                            >
                                                Next major technical change
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Next major technical change"
                                                id="NextMajorTechnicalChange"
                                                onChange={handleChangeNextMajorTechnicalChange}
                                                value={valuesM2C.NextMajorTechnicalChange}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <Typography sx={{fontSize: 'large'}}>
                                            Criticality
                                        </Typography>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='CriticalityScoreGivenByOwnAssessment'
                                            >
                                                Criticality score given by own assessment
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Criticality score given by own assessment"
                                                id="CriticalityScoreGivenByOwnAssessment"
                                                onChange={handleChangeCriticalityScoreGivenByOwnAssessment}
                                                type="number"
                                                inputProps={{min: "1", max: "5", step: "1"}}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='CurrentResponseTime'
                                            >
                                                Current response time
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Current response time"
                                                id="CurrentResponseTime"
                                                onChange={handleChangeCurrentResponseTime}
                                                value={valuesM2C.CurrentResponseTime}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="DisasterRecoveryPlan">Disaster recovery plan ?</InputLabel>
                                            <Select
                                                labelId="Disaster recovery plan ?"
                                                id="DisasterRecoveryPlan"
                                                value={valuesM2C.DisasterRecoveryPlan}
                                                label="Disaster recovery plan ?"
                                                onChange={handleChangeDisasterRecoveryPlan}
                                            >
                                                <MenuItem value='No'>No</MenuItem>
                                                <MenuItem value='Yes'>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Typography sx={{fontSize: 'large'}}>
                                            Infrastructure
                                        </Typography>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='MainInfrastructureSupplierName'
                                            >
                                                Main infrastructure supplier name
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Main infrastructure supplier name"
                                                id="MainInfrastructureSupplierName"
                                                onChange={handleChangeMainInfrastructureSupplierName}
                                                value={valuesM2C.MainInfrastructureSupplierName}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='SharedInfrastructure'
                                            >
                                                Shared infrastructure
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Shared infrastructure"
                                                id="SharedInfrastructure"
                                                onChange={handleChangeSharedInfrastructure}
                                                value={valuesM2C.SharedInfrastructure}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="Monitoring">Monitoring</InputLabel>
                                            <Select
                                                labelId="Monitoring"
                                                id="Monitoring"
                                                value={valuesM2C.Monitoring}
                                                label="Monitoring"
                                                onChange={handleChangeMonitoring}
                                            >
                                                <MenuItem value='No'>No</MenuItem>
                                                <MenuItem value='Yes'>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='SchedulingSolution'
                                            >
                                                Scheduling solution
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Scheduling solution"
                                                id="SchedulingSolution"
                                                onChange={handleChangeSchedulingSolution}
                                                value={valuesM2C.SchedulingSolution}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='FileTransferSolution'
                                            >
                                                File transfer solution
                                            </InputLabel>
                                            <OutlinedInput
                                                label="File transfer solution"
                                                id="FileTransferSolution"
                                                onChange={handleChangeFileTransferSolution}
                                                value={valuesM2C.FileTransferSolution}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='TotalServers'
                                            >
                                                Total servers
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Total servers"
                                                id="TotalServers"
                                                onChange={handleChangeTotalServers}
                                                type="number"
                                                inputProps={{min: "0", max: "1000", step: "1"}}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='ProductionServers'
                                            >
                                                Production servers
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Production servers"
                                                id="ProductionServers"
                                                onChange={handleChangeProductionServers}
                                                type="number"
                                                inputProps={{min: "0", max: "1000", step: "1"}}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='PreProductionServers'
                                            >
                                                Preproduction servers
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Preproduction servers"
                                                id="PreProductionServers"
                                                onChange={handleChangePreProductionServers}
                                                type="number"
                                                inputProps={{min: "0", max: "1000", step: "1"}}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='DevServersNumber'
                                            >
                                                Dev servers number
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Dev servers number"
                                                id="DevServersNumber"
                                                onChange={handleChangeDevServersNumber}
                                                type="number"
                                                inputProps={{min: "0", max: "1000", step: "1"}}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='MainOS'
                                            >
                                                Main OS
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Main OS"
                                                id="MainOS"
                                                onChange={handleChangeMainOS}
                                                value={valuesM2C.MainOS}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='NFSMQKafka'
                                            >
                                                NFS, MQ, Kafka ?
                                            </InputLabel>
                                            <OutlinedInput
                                                label="NFS, MQ, Kafka ?"
                                                id="NFSMQKafka"
                                                onChange={handleChangeNFSMQKafka}
                                                value={valuesM2C.NFSMQKafka}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <Typography sx={{fontSize: 'large'}}>
                                            Cloud compliance
                                        </Typography>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="HowIsTheApplicationAccessedUsed">How is the application
                                                accessed / used ?</InputLabel>
                                            <Select
                                                labelId="How is the application accessed / used ?"
                                                id="HowIsTheApplicationAccessedUsed"
                                                value={valuesM2C.HowIsTheApplicationAccessedUsed}
                                                label="How is the application accessed / used ?"
                                                onChange={handleChangeHowIsTheApplicationAccessedUsed}
                                            >
                                                <MenuItem value='5 - Browser'>Browser</MenuItem>
                                                <MenuItem value='3 - Desktop client'>Desktop client</MenuItem>
                                                <MenuItem value='1 - Remote desktop'>Remote desktop</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="IfWebApplication">If web application : usage</InputLabel>
                                            <Select
                                                labelId="If web application : usage"
                                                id="IfWebApplication"
                                                value={valuesM2C.IfWebApplication}
                                                label="If web application : usage"
                                                onChange={handleChangeIfWebApplication}
                                            >
                                                <MenuItem value='5 - Internet'>Internet</MenuItem>
                                                <MenuItem value='3 - Extranet'>Extranet</MenuItem>
                                                <MenuItem value='2 - Intranet'>Intranet</MenuItem>
                                                <MenuItem value='1 - Not web application'>Not web application</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="ExternalConnectivityWithPartners">External connectivity with
                                                partners</InputLabel>
                                            <Select
                                                labelId="External connectivity with partners"
                                                id="ExternalConnectivityWithPartners"
                                                value={valuesM2C.ExternalConnectivityWithPartners}
                                                label="External connectivity with partners"
                                                onChange={handleChangeExternalConnectivityWithPartners}
                                            >
                                                <MenuItem value='2 - No'>No</MenuItem>
                                                <MenuItem value='1 - Yes'>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="IntegrationProtocols">Integration protocols</InputLabel>
                                            <Select
                                                labelId="Integration protocols"
                                                id="IntegrationProtocols"
                                                value={valuesM2C.IntegrationProtocols}
                                                label="Integration protocols"
                                                onChange={handleChangeIntegrationProtocols}
                                            >
                                                <MenuItem value='2 - Secure Protocols'>Secure Protocols</MenuItem>
                                                <MenuItem value='1 - Unsecure protocol'>Unsecure protocols</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="LatencySensitivity">Latency sensitivity</InputLabel>
                                            <Select
                                                labelId="Latency sensitivity"
                                                id="LatencySensitivity"
                                                value={valuesM2C.LatencySensitivity}
                                                label="Latency sensitivity"
                                                onChange={handleChangeLatencySensitivity}
                                            >
                                                <MenuItem value='2 - No'>No</MenuItem>
                                                <MenuItem value='1 - Yes'>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="SensitivityToIPChange">Sensitivity to ip change</InputLabel>
                                            <Select
                                                labelId="Sensitivity to ip change"
                                                id="SensitivityToIPChange"
                                                value={valuesM2C.SensitivityToIPChange}
                                                label="Sensitivity to ip change"
                                                onChange={handleChangeSensitivityToIPChange}
                                            >
                                                <MenuItem value='5 - No'>No</MenuItem>
                                                <MenuItem value='1 - Yes'>Yes</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="HostedOnaVirtualMachine">Hosted on a virtual
                                                machine</InputLabel>
                                            <Select
                                                labelId="Hosted on a virtual machine"
                                                id="HostedOnaVirtualMachine"
                                                value={valuesM2C.HostedOnaVirtualMachine}
                                                label="Hosted on a virtual machine"
                                                onChange={handleChangeHostedOnaVirtualMachine}
                                            >
                                                <MenuItem value='5 - No'>Yes</MenuItem>
                                                <MenuItem value='1 - Yes'>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='ExternalSoftwareDependency'
                                            >
                                                External software dependency
                                            </InputLabel>
                                            <OutlinedInput
                                                label="External software dependency"
                                                id="ExternalSoftwareDependency"
                                                onChange={handleChangeExternalSoftwareDependency}
                                                value={valuesM2C.ExternalSoftwareDependency}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <Typography sx={{fontSize: 'large'}}>
                                            Others
                                        </Typography>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='AnySpecificInformationOrConstraintWeShouldKnow'
                                            >
                                                Any specific information or constraint
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Any specific information or constraint"
                                                id="AnySpecificInformationOrConstraintWeShouldKnow"
                                                onChange={handleChangeAnySpecificInformationOrConstraintWeShouldKnow}
                                                value={valuesM2C.AnySpecificInformationOrConstraintWeShouldKnow}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='SupportHoursToEndUsers'
                                            >
                                                Support hours to end users
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Support hours to end users"
                                                id="SupportHoursToEndUsers"
                                                onChange={handleChangeSupportHoursToEndUsers}
                                                value={valuesM2C.SupportHoursToEndUsers}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='CurrentApplicationAvailability'
                                            >
                                                Current application availability
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Current application availability"
                                                id="CurrentApplicationAvailability"
                                                onChange={handleChangeCurrentApplicationAvailability}
                                                value={valuesM2C.CurrentApplicationAvailability}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl sx={{textAlign: 'left'}}>
                                            <InputLabel id="PreferredCutOverWindow">Preferred cut over
                                                window</InputLabel>
                                            <Select
                                                labelId="Preferred cut over window"
                                                id="PreferredCutOverWindow"
                                                value={valuesM2C.PreferredCutOverWindow}
                                                label="Hosted on a virtual machine"
                                                onChange={handleChangePreferredCutOverWindow}
                                            >
                                                <MenuItem value='Working days and hours'>Working days and
                                                    hours</MenuItem>
                                                <MenuItem value='Working days and afterwork hours'>Working days and
                                                    afterwork hours</MenuItem>
                                                <MenuItem value='Weekend'>Weekend</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Typography sx={{fontSize: 'large'}}>
                                            SLA BaseLine
                                        </Typography>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='NumberOfTransactions'
                                            >
                                                Number of transactions
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Number of transactions"
                                                id="NumberOfTransactions"
                                                onChange={handleChangeNumberOfTransactions}
                                                type="number"
                                                inputProps={{min: "0", max: "1000", step: "1"}}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='ResponseTimeFor'
                                            >
                                                Response time for
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Response time for"
                                                id="ResponseTimeFor"
                                                onChange={handleChangeResponseTimeFor}
                                                value={valuesM2C.ResponseTimeFor}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='BatchNumber'
                                            >
                                                Batch number
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Batch number"
                                                id="BatchNumber"
                                                onChange={handleChangeBatchNumber}
                                                type="number"
                                                inputProps={{min: "0", max: "1000", step: "1"}}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='BatchWindow'
                                            >
                                                Batch window
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Batch window"
                                                id="BatchWindow"
                                                onChange={handleChangeBatchWindow}
                                                value={valuesM2C.BatchWindow}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='BatchDuration'
                                            >
                                                Batch duration
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Batch duration"
                                                id="BatchDuration"
                                                onChange={handleChangeBatchDuration}
                                                value={valuesM2C.BatchDuration}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='TestProcedureAndFunctionalTestSet'
                                            >
                                                Test procedure and functional test set
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Test procedure and functional test set"
                                                id="TestProcedureAndFunctionalTestSet"
                                                onChange={handleChangeTestProcedureAndFunctionalTestSet}
                                                value={valuesM2C.TestProcedureAndFunctionalTestSet}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                htmlFor='ExistenceOfSLAContract'
                                            >
                                                Existence of SLA contract
                                            </InputLabel>
                                            <OutlinedInput
                                                label="Existence of SLA contract"
                                                id="ExistenceOfSLAContract"
                                                onChange={handlechangeExistenceOfSLAContract}
                                                value={valuesM2C.ExistenceOfSLAContract}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <InfoIcon/>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Stack>
                                </Box>
                            </React.Fragment>
                        )
                        :
                        null
                }
                {
                    step === 2 ?
                        (
                            <Stack
                                spacing={2}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <SummaryTableComponent
                                    BIS={valuesM2C.BIS}
                                    ProjectIDInCMDB={valuesM2C.ProjectIDInCMDB}
                                    TypeAndCMDB={valuesM2C.TypeAndCMDB}
                                    ApplicationOwner={valuesM2C.ApplicationOwner}
                                    ITEResponsible={valuesM2C.ITEResponsible}
                                    VersionDate={valuesM2C.VersionDate}
                                    DocumentVersion={valuesM2C.DocumentVersion}
                                    LastUpdate={valuesM2C.LastUpdate}
                                    ApplicationNameId={valuesM2C.ApplicationNameId}
                                    ApplicationNameLabel={valuesM2C.ApplicationNameLabel}
                                    ITDomain={valuesM2C.ITDomain}
                                    GeographyCoverage={valuesM2C.GeographyCoverage}
                                    FunctionalComplexity={valuesM2C.FunctionalComplexity}
                                    SolutionType={valuesM2C.SolutionType}
                                    PackageName={valuesM2C.PackageName}
                                    DegreeOfCustomization={valuesM2C.DegreeOfCustomization}
                                    MainAppProgrammingLanguage={valuesM2C.MainAppProgrammingLanguage}
                                    SecondAppProgrammingLanguage={valuesM2C.SecondAppProgrammingLanguage}
                                    NameOfDevelopmentFramework={valuesM2C.NameOfDevelopmentFramework}
                                    NameOfTheMainDatabaseManagementSystem={valuesM2C.NameOfTheMainDatabaseManagementSystem}
                                    ApplicationServerAndVersion={valuesM2C.ApplicationServerAndVersion}
                                    TechnicalObsolescence={valuesM2C.TechnicalObsolescence}
                                    SourceCodeAvailability={valuesM2C.SourceCodeAvailability}
                                    ApplicationAuthentication={valuesM2C.ApplicationAuthentication}
                                    JavaUsed={valuesM2C.JavaUsed}
                                    JDKVersion={valuesM2C.JDKVersion}
                                    DevDedicated={valuesM2C.DevDedicated}
                                    PreprodDedicated={valuesM2C.PreprodDedicated}
                                    PreprodAtImageOfTheProd={valuesM2C.PreprodAtImageOfTheProd}
                                    TechnicalDependency={valuesM2C.TechnicalDependency}
                                    Backup={valuesM2C.Backup}
                                    ToolboxVersionsUsed={valuesM2C.ToolboxVersionsUsed}
                                    SpecificProductsEmbedded={valuesM2C.SpecificProductsEmbedded}
                                    IndusGen={valuesM2C.IndusGen}
                                    LicenseForSomeProducts={valuesM2C.LicenseForSomeProducts}
                                    CIQIntegration={valuesM2C.CIQIntegration}
                                    StopAndStartAutomatedProcess={valuesM2C.StopAndStartAutomatedProcess}
                                    CMDBUpToDate={valuesM2C.CMDBUpToDate}
                                    TimeToMarketRequiredByBusiness={valuesM2C.TimeToMarketRequiredByBusiness}
                                    NumberOfActiveEndUsers={valuesM2C.NumberOfActiveEndUsers}
                                    AreThereRegulatoryRequirements={valuesM2C.AreThereRegulatoryRequirements}
                                    TypeOfRegulatoryRequirement={valuesM2C.TypeOfRegulatoryRequirement}
                                    FunctionalDependencyOfTheApplication={valuesM2C.FunctionalDependencyOfTheApplication}
                                    InterdependenceWithOtherApplications={valuesM2C.InterdependenceWithOtherApplications}
                                    NumberOfMajorReleasesDeliveredOverTheLast12Months={valuesM2C.NumberOfMajorReleasesDeliveredOverTheLast12Months}
                                    NextMajorTechnicalChange={valuesM2C.NextMajorTechnicalChange}
                                    CriticalityScoreGivenByOwnAssessment={valuesM2C.CriticalityScoreGivenByOwnAssessment}
                                    ServiceLevelSLA={valuesM2C.ServiceLevelSLA}
                                    CurrentResponseTime={valuesM2C.CurrentResponseTime}
                                    DisasterRecoveryPlan={valuesM2C.DisasterRecoveryPlan}
                                    MainInfrastructureSupplierName={valuesM2C.MainInfrastructureSupplierName}
                                    SharedInfrastructure={valuesM2C.SharedInfrastructure}
                                    Monitoring={valuesM2C.Monitoring}
                                    SchedulingSolution={valuesM2C.SchedulingSolution}
                                    FileTransferSolution={valuesM2C.FileTransferSolution}
                                    TotalServers={valuesM2C.TotalServers}
                                    ProductionServers={valuesM2C.ProductionServers}
                                    PreProductionServers={valuesM2C.PreProductionServers}
                                    DevServersNumber={valuesM2C.DevServersNumber}
                                    MainOS={valuesM2C.MainOS}
                                    NFSMQKafka={valuesM2C.NFSMQKafka}
                                    HowIsTheApplicationAccessedUsed={valuesM2C.HowIsTheApplicationAccessedUsed}
                                    IfWebApplication={valuesM2C.IfWebApplication}
                                    ExternalConnectivityWithPartners={valuesM2C.ExternalConnectivityWithPartners}
                                    IntegrationProtocols={valuesM2C.IntegrationProtocols}
                                    LatencySensitivity={valuesM2C.LatencySensitivity}
                                    SensitivityToIPChange={valuesM2C.SensitivityToIPChange}
                                    HostedOnaVirtualMachine={valuesM2C.HostedOnaVirtualMachine}
                                    ExternalSoftwareDependency={valuesM2C.ExternalSoftwareDependency}
                                    AnySpecificInformationOrConstraintWeShouldKnow={valuesM2C.AnySpecificInformationOrConstraintWeShouldKnow}
                                    SupportHoursToEndUsers={valuesM2C.SupportHoursToEndUsers}
                                    CurrentApplicationAvailability={valuesM2C.CurrentApplicationAvailability}
                                    PreferredCutOverWindow={valuesM2C.PreferredCutOverWindow}
                                    NumberOfTransactions={valuesM2C.NumberOfTransactions}
                                    ResponseTimeFor={valuesM2C.ResponseTimeFor}
                                    BatchNumber={valuesM2C.BatchNumber}
                                    BatchWindow={valuesM2C.BatchWindow}
                                    BatchDuration={valuesM2C.BatchDuration}
                                    TestProcedureAndFunctionalTestSet={valuesM2C.TestProcedureAndFunctionalTestSet}
                                    ExistenceOfSLAContract={valuesM2C.ExistenceOfSLAContract}
                                />
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" onClick={handleCreatePDF} endIcon={<DownloadIcon/>}>
                                        Download
                                    </Button>
                                    {
                                    /*
                                    <Button variant="contained" endIcon={<SendIcon/>}>
                                        Send
                                    </Button>
                                    */
                                    }
                                </Stack>
                            </Stack>
                        )
                        :
                        null
                }
                <Stack direction="row" spacing={2}>
                    {
                        step > 0 ?
                            (
                                <Button variant='contained' component='label' disabled={step === 0}
                                        onClick={handleClickPreviousStep}>
                                    Previous
                                </Button>
                            )
                            :
                            null
                    }
                    {
                        step < 2 ?
                            (
                                <Button variant='contained' component='label' disabled={step === 2}
                                        onClick={handleClickNextStep}>
                                    Next
                                </Button>
                            )
                            :
                            null
                    }
                </Stack>
                {
                    step === 0 ?
                        (
                            <Alert severity="info" sx={{width: '100%'}}>
                                <AlertTitle>Info</AlertTitle>
                                Please note that you need to enter a valid application name from <strong>REFLEX</strong>.
                                If you enter a
                                valid application name and M2C Form isn't prefilled it's because application don't have
                                any informations about application name entered. If it's case you need to launch a
                                report in <strong>CHKEXP</strong>.
                            </Alert>
                        )
                        :
                        null
                }
            </Stack>
        </Paper>
    )
}