import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import {Dayjs} from "dayjs";

interface SummaryTableProps {
    BIS: string,
    ProjectIDInCMDB: string,
    TypeAndCMDB: string,
    ApplicationOwner: string,
    ITEResponsible: string,
    VersionDate: Dayjs | null,
    DocumentVersion: number,
    LastUpdate: Dayjs | null,
    ApplicationNameId: string,
    ApplicationNameLabel: string,
    ITDomain: string,
    GeographyCoverage: string,
    FunctionalComplexity: string,
    SolutionType: string,
    PackageName: string,
    DegreeOfCustomization: string,
    MainAppProgrammingLanguage: string,
    SecondAppProgrammingLanguage: string,
    NameOfDevelopmentFramework: string,
    NameOfTheMainDatabaseManagementSystem: string,
    ApplicationServerAndVersion: string,
    TechnicalObsolescence: string,
    SourceCodeAvailability: string,
    ApplicationAuthentication: string,
    JavaUsed: string,
    JDKVersion: string,
    DevDedicated: string,
    PreprodDedicated: string,
    PreprodAtImageOfTheProd: string,
    TechnicalDependency: string,
    Backup: string,
    ToolboxVersionsUsed: string,
    SpecificProductsEmbedded: string,
    IndusGen: string,
    LicenseForSomeProducts: string,
    CIQIntegration: string,
    StopAndStartAutomatedProcess: string,
    CMDBUpToDate: string,
    TimeToMarketRequiredByBusiness: string,
    NumberOfActiveEndUsers: string,
    AreThereRegulatoryRequirements: string,
    TypeOfRegulatoryRequirement: string,
    FunctionalDependencyOfTheApplication: string,
    InterdependenceWithOtherApplications: string,
    NumberOfMajorReleasesDeliveredOverTheLast12Months: number,
    NextMajorTechnicalChange: string,
    CriticalityScoreGivenByOwnAssessment: string,
    ServiceLevelSLA: number,
    CurrentResponseTime: string,
    DisasterRecoveryPlan: string,
    MainInfrastructureSupplierName: string,
    SharedInfrastructure: string,
    Monitoring: string,
    SchedulingSolution: string,
    FileTransferSolution: string,
    TotalServers: string,
    ProductionServers: string,
    PreProductionServers: string,
    DevServersNumber: string,
    MainOS: string,
    NFSMQKafka: string,
    HowIsTheApplicationAccessedUsed: string,
    IfWebApplication: string,
    ExternalConnectivityWithPartners: string,
    IntegrationProtocols: string,
    LatencySensitivity: string,
    SensitivityToIPChange: string,
    HostedOnaVirtualMachine: string,
    ExternalSoftwareDependency: string,
    AnySpecificInformationOrConstraintWeShouldKnow: string,
    SupportHoursToEndUsers: string,
    CurrentApplicationAvailability: string,
    PreferredCutOverWindow: string,
    NumberOfTransactions: number,
    ResponseTimeFor: string,
    BatchNumber: string,
    BatchWindow: string,
    BatchDuration: string,
    TestProcedureAndFunctionalTestSet: string,
    ExistenceOfSLAContract: string,
}

export default function SummaryTableComponent(props: SummaryTableProps) {

    const {
        BIS,
        ProjectIDInCMDB,
        TypeAndCMDB,
        ApplicationOwner,
        ITEResponsible,
        VersionDate,
        DocumentVersion,
        LastUpdate,
        ApplicationNameId,
        ApplicationNameLabel,
        ITDomain,
        GeographyCoverage,
        FunctionalComplexity,
        SolutionType,
        PackageName,
        DegreeOfCustomization,
        MainAppProgrammingLanguage,
        SecondAppProgrammingLanguage,
        NameOfDevelopmentFramework,
        NameOfTheMainDatabaseManagementSystem,
        ApplicationServerAndVersion,
        TechnicalObsolescence,
        SourceCodeAvailability,
        ApplicationAuthentication,
        JavaUsed,
        JDKVersion,
        DevDedicated,
        PreprodDedicated,
        PreprodAtImageOfTheProd,
        TechnicalDependency,
        Backup,
        ToolboxVersionsUsed,
        SpecificProductsEmbedded,
        IndusGen,
        LicenseForSomeProducts,
        CIQIntegration,
        StopAndStartAutomatedProcess,
        CMDBUpToDate,
        TimeToMarketRequiredByBusiness,
        NumberOfActiveEndUsers,
        AreThereRegulatoryRequirements,
        TypeOfRegulatoryRequirement,
        FunctionalDependencyOfTheApplication,
        InterdependenceWithOtherApplications,
        NumberOfMajorReleasesDeliveredOverTheLast12Months,
        NextMajorTechnicalChange,
        CriticalityScoreGivenByOwnAssessment,
        ServiceLevelSLA,
        CurrentResponseTime,
        DisasterRecoveryPlan,
        MainInfrastructureSupplierName,
        SharedInfrastructure,
        Monitoring,
        SchedulingSolution,
        FileTransferSolution,
        TotalServers,
        ProductionServers,
        PreProductionServers,
        DevServersNumber,
        MainOS,
        NFSMQKafka,
        HowIsTheApplicationAccessedUsed,
        IfWebApplication,
        ExternalConnectivityWithPartners,
        IntegrationProtocols,
        LatencySensitivity,
        SensitivityToIPChange,
        HostedOnaVirtualMachine,
        ExternalSoftwareDependency,
        AnySpecificInformationOrConstraintWeShouldKnow,
        SupportHoursToEndUsers,
        CurrentApplicationAvailability,
        PreferredCutOverWindow,
        NumberOfTransactions,
        ResponseTimeFor,
        BatchNumber,
        BatchWindow,
        BatchDuration,
        TestProcedureAndFunctionalTestSet,
        ExistenceOfSLAContract
    } = props;

    function createData(
        name: string,
        value: string,
    ) {
        return {name, value};
    }

    const rows = [
        createData('BIS', props.BIS),
        createData('Project id in CMDB', props.ProjectIDInCMDB),
        createData('Type and CMDB', props.TypeAndCMDB),
        createData('Application owner', props.ApplicationOwner),
        createData('ITE responsible', props.ITEResponsible),
        createData('Version date', props.VersionDate === null ? '' : props.VersionDate.toString()),
        createData('Document version', props.DocumentVersion.toString()),
        createData('Last update', props.LastUpdate === null ? '' : props.LastUpdate.toString()),
        createData('Application name id', props.ApplicationNameId),
        createData('Application name label', props.ApplicationNameLabel),
        createData('IT domain', props.ITDomain),
        createData('Geography coverage', props.GeographyCoverage),
        createData('Functional complexity', props.FunctionalComplexity),
        createData('Solution type', props.SolutionType),
        createData('Package name', props.PackageName),
        createData('Degree of customization', props.DegreeOfCustomization),
        createData('Main app programming language', props.MainAppProgrammingLanguage),
        createData('Second app programming language', props.SecondAppProgrammingLanguage),
        createData('Name of development framework', props.NameOfDevelopmentFramework),
        createData('Name of the main database management system', props.NameOfTheMainDatabaseManagementSystem),
        createData('Application server and version', props.ApplicationServerAndVersion),
        createData('Technical obsolescence', props.TechnicalObsolescence),
        createData('Source code availability', props.SourceCodeAvailability),
        createData('Application authentication', props.ApplicationAuthentication),
        createData('Java used', props.JavaUsed),
        createData('JDK version', props.JDKVersion),
        createData('Dev dedicated', props.DevDedicated),
        createData('Preprod dedicated', props.PreprodDedicated),
        createData('Preprod at image of the prod', props.PreprodAtImageOfTheProd),
        createData('Technical dependency', props.TechnicalDependency),
        createData('Backup', props.Backup),
        createData('Toolbox versions used', props.ToolboxVersionsUsed),
        createData('Specific products embedded', props.SpecificProductsEmbedded),
        createData('IndusGen', props.IndusGen),
        createData('License for some products', props.LicenseForSomeProducts),
        createData('CIQ integration', props.CIQIntegration),
        createData('Stop and start automated process', props.StopAndStartAutomatedProcess),
        createData('CMDB up to date', props.CMDBUpToDate),
        createData('Time to market required by business', props.TimeToMarketRequiredByBusiness),
        createData('Number of active end users', props.NumberOfActiveEndUsers),
        createData('Are there regulatory requirements', props.AreThereRegulatoryRequirements),
        createData('Type of regulatory requirement', props.TypeOfRegulatoryRequirement),
        createData('Functional dependency of the application', props.FunctionalDependencyOfTheApplication),
        createData('Interdependence with other applications', props.InterdependenceWithOtherApplications),
        createData('Number of major releases delivered over the last 12 months', props.NumberOfMajorReleasesDeliveredOverTheLast12Months === null ? '' : props.NumberOfMajorReleasesDeliveredOverTheLast12Months.toString()),
        createData('Next major technical change', props.NextMajorTechnicalChange),
        createData('Criticality score given by own assessment', props.CriticalityScoreGivenByOwnAssessment),
        createData('Service level agreement (SLA)', props.ServiceLevelSLA === null ? '' : props.ServiceLevelSLA.toString()),
        createData('Current response time', props.CurrentResponseTime),
        createData('Disaster recovery plan', props.DisasterRecoveryPlan),
        createData('Main infrastructure supplier name', props.MainInfrastructureSupplierName),
        createData('Shared infrastructure', props.SharedInfrastructure),
        createData('Monitoring', props.Monitoring),
        createData('Scheduling solution', props.SchedulingSolution),
        createData('File transfer solution', props.FileTransferSolution),
        createData('Total servers', props.TotalServers),
        createData('Production servers', props.ProductionServers),
        createData('Pre production servers', props.PreProductionServers),
        createData('Dev servers number', props.DevServersNumber),
        createData('Main OS', props.MainOS),
        createData('NFS, MQ, Kafka or something else ?', props.NFSMQKafka),
        createData('How is the application accessed used', props.HowIsTheApplicationAccessedUsed),
        createData('If web application', props.IfWebApplication),
        createData('External connectivity with partners', props.ExternalConnectivityWithPartners),
        createData('Integration protocols', props.IntegrationProtocols),
        createData('Latency sensitivity', props.LatencySensitivity),
        createData('Sensitivity to IP change', props.SensitivityToIPChange),
        createData('Hosted on a virtual machine', props.HostedOnaVirtualMachine),
        createData('External software dependency', props.ExternalSoftwareDependency),
        createData('Any specific information or constraint we should know', props.AnySpecificInformationOrConstraintWeShouldKnow),
        createData('Support hours to end users', props.SupportHoursToEndUsers),
        createData('Current application availability', props.CurrentApplicationAvailability),
        createData('Preferred cut over window', props.PreferredCutOverWindow),
        createData('Number of transactions', props.NumberOfTransactions === null ? '' : props.NumberOfTransactions.toString()),
        createData('Response time for', props.ResponseTimeFor),
        createData('Batch number', props.BatchNumber),
        createData('Batch window', props.BatchWindow),
        createData('Batch duration', props.BatchDuration),
        createData('Test procedure and functional test set', props.TestProcedureAndFunctionalTestSet),
        createData('Existence of SLA contract', props.ExistenceOfSLAContract),
    ]

    return (
        <Table sx={{minWidth: '80%'}} size="small" aria-label="summary dense table" id="M2CSummaryTable">
            <TableHead>
                <TableRow>
                    <TableCell align="center" sx={{fontWeight:'bold'}}>
                        State
                    </TableCell>
                    <TableCell align="center" sx={{fontWeight:'bold'}}>
                        Value
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row)=>(
                    <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                    >
                        <TableCell
                            component="th"
                            scope="row"
                            align="center"
                        >
                            {row.name}
                        </TableCell>
                        <TableCell align="center">
                            {row.value}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}