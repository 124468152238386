import {Paper, Typography} from "@mui/material";

export default function DashboardComponent () {
    return(
        <Paper
            sx={{
                height: "auto",
                minWidth: "80vw",
                maxWidth: '80vw',
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: 'calc(2% + 70px)',
                padding: '30px',
                borderRadius: '10px',
                textAlign: 'center',
            }}
            elevation={3}
        >
            <Typography variant='h2'>
                Dashboard page is in progress...
            </Typography>
        </Paper>
    )
}