import {Stack, Button, Typography} from "@mui/material";
import Authentication from "../Authentication/Authentication";
import EnvConfig from "../EnvConfig/EnvConfig";

export default function ErrorPage(){

    const handleClickConnectWithOtherAccount = () => {
        Authentication.INSTANCE.Logout().then(()=>window.location.href='/home');
    }

    return(
        <Stack
            sx={{width:'100vw', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}
            spacing={2}
        >
            <Typography sx={{fontFamily: 'EncodeSansExpanded-SemiBold', color:'#d32f2f', textAlign:'center'}} variant="h4">
                Error ! account with which you try to access don't have SA for this application
            </Typography>
            <Button variant='contained' onClick={handleClickConnectWithOtherAccount}>
                Connect with another Account
            </Button>
        </Stack>
    )
}