import {Checkbox, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React from "react";

interface EnhancedTableProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
}

export default function TableHeaderComponent(props: EnhancedTableProps){

    const { numSelected,onSelectAllClick,rowCount } = props;

    return(
        <TableHead>
            <TableRow sx={{textTransform:"uppercase"}}>
                <TableCell align="center">
                    <Checkbox
                        color="warning"
                        onChange={onSelectAllClick}
                        indeterminate={numSelected > 0 && numSelected < rowCount }
                        checked={rowCount>0 && numSelected === rowCount}
                        inputProps={{
                            'aria-label': 'select all rows',
                        }}
                    />
                </TableCell>
                <TableCell
                    padding='none'
                >
                    id
                </TableCell>
                <TableCell key="ReferentialName" align="center">
                    <TableSortLabel>
                        Referential Name
                    </TableSortLabel>
                </TableCell>
                <TableCell key="ValueName" align="center">
                    <TableSortLabel>
                        Value Name
                    </TableSortLabel>
                </TableCell>
                <TableCell key="ReferentialValue" align="center">
                    <TableSortLabel>
                        Referential value
                    </TableSortLabel>
                </TableCell>
                <TableCell key="ReferentialDate" align="center">
                    <AccessTimeIcon/>
                </TableCell>
                <TableCell key="ProductionValue" align="center">
                    <TableSortLabel>
                        production value
                    </TableSortLabel>
                </TableCell>
                <TableCell key="ProductionDate" align="center">
                    <AccessTimeIcon/>
                </TableCell>
                <TableCell key="Action" align="center">
                    <TableSortLabel>
                        action
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}