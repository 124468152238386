import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Table,
    TableContainer,
} from "@mui/material";
import TableHeaderComponent from "./TableHeaderComponent/TableHeaderComponent";
import TableBodyComponent from "./TableBodyComponent/TableBodyComponent";
import TableFooterComponent from "./TableFooterComponent/TableFooterComponent";
import React from "react";
import EnhancedTableToolbarComponent
    from "./TableHeaderComponent/EnhancedTableToolbarComponent/EnhancedTableToolbarComponent";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

export default function TableComponent() {

    const RandomDate = (start:any, end:any) => {
        let date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
        return [date.getDate().toString().padStart(2, '0'),date.getMonth().toString().padStart(2, '0'),date.getFullYear(),].join('/')+' at '+[date.getHours()+'h',date.getMinutes()+'m',date.getSeconds()+'s'].join('');
    }

    const RandomString = (length:number) => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const createRows = (size: number) => {
        let value = new Array(size);
        for (let i = 0; i < size; i++) {
            value.fill([i,['Reflex', 'PSASM', 'Indusgen', 'DocInfo', 'SVN', 'GitHub'][Math.floor(Math.random() * 5)], ['Version Number', 'Production Date', 'Date of version', 'End date scalability', 'Indus', 'PGA', 'PTA'][Math.floor(Math.random() * 7)],RandomString(Math.floor(Math.random()*9+1)),RandomDate(new Date(2012, 0, 1), new Date()).toString(),RandomString(Math.floor(Math.random()*9+1)),RandomDate(new Date(2012, 0, 1), new Date()).toString()], i, i + 1);
        }
        return value;
    }

    const rows = createRows(1000);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [ value, setValue ] = React.useState('');
    const [ applications, setApplications ] = React.useState<string[]>([]);

    const [selected, setSelected] = React.useState<readonly string[]>([]);

    const saveApplications = () => {
        if(applications.find(element => element === value) === undefined){
            return setApplications(applications => [...applications, value]);
        }
        return;
    }

    const deleteApplications = (valueToRemove:string) => {
        return setApplications(applications => applications.filter(element => element !== valueToRemove));
    }

    const handleCreateNewItem = (e:any) => {
        e.preventDefault();
        setValue(e.target.value)
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n:Array<any>) => n[0]);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    return (
        <Paper
            sx={{
                height: "auto",
                minWidth: "80vw",
                maxWidth: '80vw',
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: 'calc(2% + 70px)',
                padding: '30px',
                borderRadius: '10px'
            }}
            elevation={3}
        >
            <Box sx={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                <FormControl variant='outlined'>
                    <InputLabel htmlFor="ApplicationName">Application Name</InputLabel>
                    <OutlinedInput
                        id='ApplicationName'
                        type='text'
                        value={value}
                        onChange={handleCreateNewItem}
                        label='Application Name'
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton onClick={saveApplications}>
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Box>
            <Box sx={{textAlign:'center'}}>
                {
                    applications.map((value,index)=>{
                        return(
                            <ButtonGroup key={index} sx={{margin:'10px'}}>
                                <Button>
                                    <FavoriteBorderIcon/>
                                </Button>
                                <Button>
                                    {value}
                                </Button>
                                <Button sx={{'&:hover':{color:'#f44336', borderColor:'#f44336'}}} onClick={()=>deleteApplications(value)}>
                                    <DeleteIcon/>
                                </Button>
                            </ButtonGroup>
                        )
                    })
                }
            </Box>
            <EnhancedTableToolbarComponent numSelected={selected.length} value={value}/>
            <TableContainer>
                <Table>
                    <TableHeaderComponent
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={rows.length}
                    />
                    <TableBodyComponent
                        rows={rows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        isSelected={isSelected}
                        handleClick={handleClick}
                    />
                    <TableFooterComponent
                        rows={rows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Table>
            </TableContainer>
        </Paper>
    )

}