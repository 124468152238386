import React from "react";
import Authentication from "../../Authentication/Authentication";
class LoginComponent extends React.Component{

    render(){

        Authentication.INSTANCE.ensureLog().then(() => window.location.href='/home').catch(r => console.log(r));

        return(
            <div>
            </div>
        )
    }
}

export default LoginComponent;