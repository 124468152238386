import {TableFooter, TablePagination, TableRow} from "@mui/material";
import React from "react";

interface TableFooterComponentProps{
    rows: Array<any>,
    rowsPerPage: number,
    page: number,
    handleChangePage: (event: unknown, newPage: number) => void,
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void,
}


export default function TableFooterComponent(props: TableFooterComponentProps){

    const { rows,rowsPerPage,page,handleChangePage,handleChangeRowsPerPage } = props;

    return(
        <TableFooter>
            <TableRow>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableRow>
        </TableFooter>
    )
}