function GetEnv(name:string,defaultValue:string):string{
    // @ts-ignore
    return process.env[name] === undefined ? defaultValue : process.env[name]
}

const EnvConfig = {
    REACT_APP_AUTH_AUTHORITY:
        GetEnv("REACT_APP_AUTH_AUTHORITY","AUTH_AUTHORITY"),
    REACT_APP_AUTH_CLIENT_ID:
        GetEnv("REACT_APP_AUTH_CLIENT_ID","AUTH_CLIENT_ID"),
    REACT_APP_AUTH_REDIRECT_URI:
        GetEnv("REACT_APP_AUTH_REDIRECT_URI","AUTH_REDIRECT_URI"),
    REACT_APP_AUTH_AUTHORIZATION_ENDPOINT:
        GetEnv("REACT_APP_AUTH_AUTHORIZATION_ENDPOINT","AUTH_AUTHORIZATION_ENDPOINT"),
    REACT_APP_AUTH_USER_ENDPOINT:
        GetEnv("REACT_APP_AUTH_USER_ENDPOINT","AUTH_USER_ENDPOINT"),
    REACT_APP_AUTH_END_SESSION_ENDPOINT:
        GetEnv("REACT_APP_AUTH_END_SESSION_ENDPOINT","AUTH_END_SESSION_ENDPOINT"),
    REACT_APP_AUTH_TOKEN_ENDPOINT:
        GetEnv("REACT_APP_AUTH_TOKEN_ENDPOINT","AUTH_TOKEN_ENDPOINT"),
    REACT_APP_AUTH_REVOCATION_ENDPOINT:
        GetEnv("REACT_APP_AUTH_REVOCATION_ENDPOINT","AUTH_REVOCATION_ENDPOINT"),
    REACT_APP_AUTH_URL:
        GetEnv("REACT_APP_AUTH_URL","AUTH_URL"),
    REACT_APP_ENVIRONMENT:
        GetEnv("REACT_APP_ENVIRONMENT","ENVIRONMENT"),
}


export default EnvConfig;