import React from "react";
import Authentication from "../../Authentication/Authentication";
import {Box, LinearProgress} from "@mui/material";
import './CallbackStyle.css';


class CallbackComponent extends React.Component{

    render(){

        Authentication.INSTANCE.Callback().then(()=>window.location.href='/home');

        return(
            <div className='DivCallback'>
                <Box sx={{width: '80%'}}>
                    <LinearProgress/>
                </Box>
            </div>
        )
    }
}

export default CallbackComponent;