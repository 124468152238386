import React from 'react';
import LoginComponent from "./Components/LoginComponent/LoginComponent";
import CallbackComponent from "./Components/CallbackComponent/CallbackComponent";
import HomeComponent from "./Components/HomeComponent/HomeComponent";
import {Route, Routes} from "react-router-dom";
import ToolsComponent from "./Components/ToolsComponent/ToolsComponent";
import NavbarComponent from "./Components/NavbarComponent/NavbarComponent";
import {Alert, createTheme, CssBaseline, Link, ThemeProvider} from "@mui/material";
import useLocalStorage from "use-local-storage";
import DashboardComponent from "./Components/DashboardComponent/DashboardComponent";
import TEICHComponent from "./Components/TEICHComponent/TEICHComponent";
import {SnackbarProvider} from "notistack";
import M2CFormComponent from './Components/TEICHComponent/M2CFormComponent/M2CFormComponent';
import CloudDiscoveryComponent from "./Components/DiscoveryComponents/CloudDiscoveryComponent";
import ErrorPage from "./Pages/ErrorPage";
import EnvConfig from './EnvConfig/EnvConfig';

const routes = [
    {path: '/', element : <LoginComponent/>},
    {path: '/callback', element : <CallbackComponent/>},
    {path: '/home', element : <HomeComponent/>},
    {path: '/tools', element : <ToolsComponent/>},
    {path: '/Dashboard', element: <DashboardComponent/>},
    {path: '/LegacyDiscovery', element: <TEICHComponent />},
    {path: '/CloudDiscovery', element: <CloudDiscoveryComponent />},
    {path: '/M2CForm', element: <M2CFormComponent />},
    {path: '/error', element: <ErrorPage />},
]

const App = () => {

    const [prefmode, setMode] = useLocalStorage("preferred mode", true);

    const changeMode = () => {
        return setMode(!prefmode);
    }

    let DarkTheme = createTheme({
        palette:{
            mode: 'dark'
        }
    });

    let LightTheme =  createTheme({
        palette:{
            mode: 'light'
        }
    });

    return (
        <SnackbarProvider maxSnack={3}>
            <ThemeProvider theme={prefmode ? DarkTheme : LightTheme}>
                <CssBaseline enableColorScheme/>
                {
                   EnvConfig.REACT_APP_ENVIRONMENT == '0' ?
                       (
                           <Alert severity="warning" sx={{position:'fixed',bottom:0, width:'100vw', display:'flex', alignItems: 'center', justifyContent : 'center', left:0, zIndex: 10}} variant="outlined">
                               Warning : You are in preproduction website ! Version is not stable, go to <Link href="https://www.sentry.stellantis.com/" underline="none" target="_blank" rel="noopener">production website</Link> in priority.
                           </Alert>
                       )
                       :
                       null
                }
                {window.location.pathname !== '/error' ? <NavbarComponent setMode={changeMode} mode={prefmode}/> : null}
                <Routes>
                    {
                        routes.map(({path,element},index) =>(
                            <Route key={index} path={path} element={element}/>
                        ))
                    }
                </Routes>
            </ThemeProvider>
        </SnackbarProvider>
    );

}

export default App;
