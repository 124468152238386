import React from "react";
import {Box} from "@mui/material";
import './HomeStyle.css';

export default function HomeComponent(){

    const stars = Array.from(Array(20).keys());

    return(
        <div id='mainDivHomeComponent'>
            <div className="night">
                {stars.map((star)=><div key={star} className="shooting_star"/>)}
            </div>
            <Box component="img" src={require("../../Assets/logo512.png")} sx={{width:"30%",aspectRatio:"998/714", position: "absolute"}}/>
        </div>
    )

}
