import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import ProfileComponent from "./ProfileComponent/ProfileComponent";
import {Drawer, ListItemButton, List, ListItemIcon, ListItemText, Collapse} from '@mui/material';
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HelpIcon from '@mui/icons-material/Help';
import PublicIcon from '@mui/icons-material/Public';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import CloudIcon from '@mui/icons-material/Cloud';
import StorageIcon from '@mui/icons-material/Storage';
import SearchIcon from '@mui/icons-material/Search';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ListAltIcon from '@mui/icons-material/ListAlt';

interface State{
    drawer: boolean,
    expandHelp: boolean,
    expandDiscovery: boolean,
    expandTEICH: boolean,
}

export default function NavbarComponent(props:any){

    const [navbarStates, setNavbarStates] = React.useState<State>({
        drawer: false,
        expandHelp: false,
        expandDiscovery: false,
        expandTEICH: false,
    })

    const DrawerOpener = () => {
        setNavbarStates({
            ...navbarStates,
            drawer: !navbarStates.drawer,
            expandHelp: false,
            expandDiscovery: false,
            expandTEICH: false,
        })
    }

    const expandHelp = () => {
        setNavbarStates({
            ...navbarStates,
            expandHelp: !navbarStates.expandHelp,
        })
    }

    const expandDiscovery = () => {
        setNavbarStates({
            ...navbarStates,
            expandDiscovery: !navbarStates.expandDiscovery,
        })
    }

    const expandTEICH = () => {
        setNavbarStates({
            ...navbarStates,
            expandTEICH: !navbarStates.expandTEICH,
        })
    }

    return (
        <React.Fragment>
            <AppBar position="fixed">
                <Container maxWidth='xl' sx={{display:'flex',flexDirection:'row',alignItems:'center', padding:'5px'}}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={DrawerOpener}
                        sx={{mr:{xs:'auto',md:0}}}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/home"
                        sx={{
                            display: {xs:'none',md:'block'},
                            mr: 'auto',
                            ml:'auto',
                            fontFamily: 'EncodeSansExpanded-SemiBold',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        SENTRY
                    </Typography>
                    <ProfileComponent setMode={props.setMode} mode={props.mode}/>
                </Container>
            </AppBar>
            <Drawer
                anchor="left"
                open={navbarStates.drawer}
                onClose={DrawerOpener}
            >
                <List
                    sx={{ width: '100%', maxWidth: 360 }}
                    component="nav"
                >
                    <ListItemButton onClick={expandHelp}>
                        <ListItemIcon>
                            <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Help" />
                        {navbarStates.expandHelp ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={navbarStates.expandHelp} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} onClick={()=>window.open('mailto:sentry@stellantis.com')}>
                                <ListItemIcon>
                                    <AlternateEmailIcon />
                                </ListItemIcon>
                                <ListItemText primary="Contact" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={()=>window.open('https://wiki-sentry.stellantis.com','_blank')}>
                                <ListItemIcon>
                                    <PublicIcon />
                                </ListItemIcon>
                                <ListItemText primary="Wiki" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={()=>window.open('https://web.yammer.com/main/org/mpsa.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMTQ1Njc3NzQyMDgifQ/all','_blank')}>
                                <ListItemIcon>
                                    <Diversity3Icon />
                                </ListItemIcon>
                                <ListItemText primary="Yammer" />
                            </ListItemButton>
                        </List>
                    </Collapse>

                    <ListItemButton onClick={()=>window.location.href='/Tools'}>
                        <ListItemIcon>
                            <TableChartOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tools" />
                    </ListItemButton>

                    <ListItemButton onClick={()=>window.location.href='/Dashboard'}>
                        <ListItemIcon>
                            <DashboardCustomizeOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItemButton>

                    <ListItemButton onClick={expandDiscovery}>
                        <ListItemIcon>
                            <SearchIcon />
                        </ListItemIcon>
                        <ListItemText primary="Discovery" />
                        {navbarStates.expandDiscovery ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={navbarStates.expandDiscovery} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} onClick={()=>window.location.href='/LegacyDiscovery'}>
                                <ListItemIcon>
                                    <StorageIcon />
                                </ListItemIcon>
                                <ListItemText primary="Legacy Discovery" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={()=>window.location.href='/CloudDiscovery'}>
                                <ListItemIcon>
                                    <CloudIcon />
                                </ListItemIcon>
                                <ListItemText primary="Cloud Discovery" />
                            </ListItemButton>
                        </List>
                    </Collapse>

                    <ListItemButton onClick={expandTEICH}>
                        <ListItemIcon>
                            <CloudUploadIcon />
                        </ListItemIcon>
                        <ListItemText primary="TEICH" />
                        {navbarStates.expandTEICH ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={navbarStates.expandTEICH} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} onClick={()=>window.location.href='/M2CForm'}>
                                <ListItemIcon>
                                    <ListAltIcon />
                                </ListItemIcon>
                                <ListItemText primary="M2C form" />
                            </ListItemButton>
                        </List>
                    </Collapse>

                </List>
            </Drawer>
        </React.Fragment>
    );
}