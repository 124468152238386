import {Checkbox, TableBody, TableCell, TableRow, Tooltip, Typography} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from "react";
import IconButton from "@mui/material/IconButton";

interface TableBodyProps {
    rows: Array<any>,
    rowsPerPage: number,
    isSelected: (id: string) => boolean,
    handleClick: (event: React.MouseEvent<unknown>, name: string) => void,
    page: number,

}

export default function TableBodyComponent(props: TableBodyProps){

    const {rows, rowsPerPage, isSelected, handleClick, page} = props;

    return(
        <TableBody>
            {rows.slice(page * rowsPerPage , page * rowsPerPage + rowsPerPage).map(([id,ReferentialName,ValueName,ReferentialValue,ReferentialDate,ProductionValue,ProductionDate]:any, index:any)=>
                {
                    const isItemSelected = isSelected(id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return(
                        <TableRow
                            hover
                            onClick={(event) => handleClick(event, id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={id}
                            selected={isItemSelected}
                        >
                            <TableCell
                                align="center"
                                padding="checkbox"
                            >
                                <Checkbox
                                    color='success'
                                    checked={isItemSelected}
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </TableCell>
                            <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                            >
                                {id}
                            </TableCell>
                            <TableCell align="center">
                                <Typography>
                                    {ReferentialName}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Tooltip title="Source : CHKEXP">
                                    <Typography>
                                        {ValueName}
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                                <Typography>
                                    {ReferentialValue}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography>
                                    {ReferentialDate}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography>
                                    {ProductionValue}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography>
                                    {ProductionDate}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <IconButton>
                                    <MoreVertIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                }
            )}
        </TableBody>
    )
}